<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlSbglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :receiveTreedata="orgsTree" :isShowCheck="false" :isSelectAll="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" :hasIcon="true" :incomeTreeData="true" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" @tableColumnClick="tableColumnClick"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>
    

    <!-- 添加弹窗 -->
    <el-dialog :title="addTitle[titleIndex]" :visible.sync="FormDialog" width="40%" @close="clearData" class="special">
      <el-form :model="deviceForm" :rules="deviceFormRules" ref="deviceForm" label-width="240px" class="demo-deviceForm" style="height: 500px;overflow-y: auto;padding-right: 30px;">
        <el-form-item label="设备编号" prop="DeviceNo">
          <!-- <el-input v-model="deviceForm.DeviceNo" type="number" maxlength="8" ></el-input> -->
          <el-input v-model="deviceForm.DeviceNo" type="text" placeholder="请输入8位设备编号" :maxlength="8" @input="handleInput"></el-input>
        </el-form-item>
        <el-form-item label="通用密码" prop="CommonPwd">
          <el-input v-model="deviceForm.CommonPwd" type="text" placeholder="请输入4位通用密码" :maxlength="4" @input="handleInputPwd"></el-input>
        </el-form-item>
        <el-form-item label="报警发送间隔" prop="AlarmSendInterval">
          <el-input-number v-model="deviceForm.AlarmSendInterval" style="width: 100%;" :min="0" :max="9999999"></el-input-number>
        </el-form-item>
        <el-form-item label="软件版本号" prop="VersionNo">
          <el-input v-model="deviceForm.VersionNo" type="text" placeholder="请输入8位软件版本号" :maxlength="8" @input="handleInputver" ></el-input>
        </el-form-item>
        <el-form-item label="安装地点" prop="InstalledLocation">
          <!-- <el-input v-model="deviceForm.InstalledLocation"><el-button slot="append" icon="el-icon-map-location" @click="showMap = true"></el-button></el-input>
          <TMap @cancel="showMap = false" @map-confirm="confirmLocation" :visible="showMap" v-if="showMap"></TMap> -->
          <el-input v-model="deviceForm.InstalledLocation" placeholder="请输入安装地点"></el-input>
        </el-form-item>
        <el-form-item label="经度坐标" prop="Longitude">
          <el-input-number v-model="deviceForm.Longitude" style="width: 100%;" :min="-180" :max="180"></el-input-number>
        </el-form-item>
        <el-form-item label="纬度坐标" prop="Latitude">
          <el-input-number v-model="deviceForm.Latitude"  style="width: 100%;" :min="-90" :max="90"></el-input-number>
        </el-form-item>
        <el-form-item label="安装时间" prop="InstalledTime">
          <el-date-picker
              v-model="deviceForm.InstalledTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用水定额(m³)" prop="WaterConsumptionNorm">
          <!-- <el-input v-model="deviceForm.WaterConsumptionNorm"></el-input> -->
          <el-input-number v-model="deviceForm.WaterConsumptionNorm" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
        </el-form-item>
        <el-form-item label="灌溉亩数(亩)" prop="IrrigatedAcreage">
          <!-- <el-input v-model="deviceForm.IrrigatedAcreage"></el-input> -->
          <el-input-number v-model="deviceForm.IrrigatedAcreage" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
        </el-form-item>
        <el-form-item label="作物类型" prop="CropTypeName">
          <el-input v-model="deviceForm.CropTypeName" placeholder="请输入作物类型"></el-input>
          <!-- <el-select v-model="deviceForm.CropTypeName" placeholder="请选择作物类型" style="width: 100%;">
            <el-option :label="i.name" :value="i.dtCode" v-for="(i,index) in $store.state.cropType" :key="'cropType' + index"></el-option>
          </el-select> -->
        </el-form-item>
        <!-- {{ deviceForm.BelongAreaLinks }} -->
        <el-form-item label="分支机构" prop="BelongAreaLinks">
          <el-cascader placeholder="请选择分支机构(可搜索)"  v-model="deviceForm.BelongAreaLinks" :options="orgsTrees"  :props="{ checkStrictly: true }" filterable @change="getBelongAreaLinks"></el-cascader>
        </el-form-item>
        <el-form-item label="设备安装图片" prop="InstalledImage">
          <el-upload  :headers="tokenHeader" :action="baseURL +'/Files/Upload'" :file-list="fileList"   ref="uploadPic" list-type="picture-card"  name="files" :on-success="handleUploadSuccess" :on-exceed="handleExceed" :limit="1">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" >
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)" >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span v-if="!disabledImage" class="el-upload-list__item-delete"  @click="handleRemove(file)" >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <el-dialog :visible.sync="dialogImageVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item label="设备描述" prop="DeviceRemark">
          <el-input type="textarea" v-model="deviceForm.DeviceRemark" :maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="DeviceTypeId">
          <el-select v-model="deviceForm.DeviceTypeId" placeholder="请选择设备类型" style="width: 100%;" @change="getDeviceTypeName">
            <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in $store.state.deviceType" :key="'deviceType' + jdx"></el-option>
          </el-select>
        </el-form-item>
        <!-- 测控一体化闸门 -->
        <template v-if="deviceForm.DeviceTypeId == 'HS_CKYTHZM'">
          <el-form-item label="闸板宽(mm)" prop="GateWidth">
            <!-- <el-input v-model="deviceForm.GateWidth"></el-input> -->
            <el-input-number v-model="deviceForm.GateWidth" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="闸板高(mm)" prop="GateHeight">
            <!-- <el-input v-model="deviceForm.GateHeight"></el-input> -->
            <el-input-number v-model="deviceForm.GateHeight" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="闸板最大行程(mm)" prop="GateMaximumStroke">
            <!-- <el-input v-model="deviceForm.GateMaximumStroke"></el-input> -->
            <el-input-number v-model="deviceForm.GateMaximumStroke" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="闸前设备" prop="GateFrontEquipmentId">
            <el-select v-model="deviceForm.GateFrontEquipmentId" placeholder="请选择闸前设备" style="width: 100%;" @change ="getGateFrontEquipment">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in dictionary('HS_Device_GateFrontEquipment')" :key="'GateFrontEquipmentId' + jdx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="闸后设备" prop="GateBackEquipmentId">
            <el-select v-model="deviceForm.GateBackEquipmentId" placeholder="请选择闸后设备" style="width: 100%;" @change="getGateBackEquipment">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in GateBackEquipmentData" :key="'GateBackEquipmentId' + jdx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供电方式" prop="PowerSupplyMode">
            <el-select v-model="deviceForm.PowerSupplyMode" placeholder="请选择供电方式" style="width: 100%;">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in  dictionary('HS_Device_PowerSupplyMode')" :key="'PowerSupplyMode' + jdx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电机电压(V)" prop="MotorVoltage">
            <!-- <el-input v-model="deviceForm.MotorVoltage"></el-input> -->
            <el-input-number v-model="deviceForm.MotorVoltage" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="电机功率(W)" prop="MotorPower">
            <!-- <el-input v-model="deviceForm.MotorPower"></el-input> -->
            <el-input-number v-model="deviceForm.MotorPower" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
        </template>
        <!-- 箱式超声波明渠流量计 -->
        <template v-if="deviceForm.DeviceTypeId == 'HS_XSCSBMQLLJ'">
          <el-form-item label="测箱宽h(mm)" prop="MeasuringBoxWidth">
            <!-- <el-input v-model="deviceForm.MeasuringBoxWidth"></el-input> -->
            <el-input-number v-model="deviceForm.MeasuringBoxWidth" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="测箱高w(mm)" prop="MeasuringBoxHeight">
            <!-- <el-input v-model="deviceForm.MeasuringBoxHeight"></el-input> -->
            <el-input-number v-model="deviceForm.MeasuringBoxHeight" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="换能器数量(n)" prop="TransducerCount">
            <!-- <el-input v-model="deviceForm.TransducerCount"></el-input> -->
            <el-input-number v-model="deviceForm.TransducerCount" :precision="0" :step="1" :min="4" :max="32" style="width: 100%;"></el-input-number>
          </el-form-item>
          <!-- <el-form-item label="最下层换能器距离底板距(mm)" prop="TransducerLowestLevelDistance">
            <el-input v-model="deviceForm.TransducerLowestLevelDistance"></el-input>
          </el-form-item>
          <el-form-item label="最上层换能器距离顶板距(mm)" prop="TransducerTopmostLevelDistance">
            <el-input v-model="deviceForm.TransducerTopmostLevelDistance"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="换能器分布规律" prop="TransducerPattern">
            <el-input v-model="deviceForm.TransducerPattern"></el-input>
          </el-form-item> -->
          <el-form-item label="换能器频率" prop="TransducerFrequency">
            <!-- <el-input v-model="deviceForm.TransducerFrequency"></el-input> -->
            <el-select v-model="deviceForm.TransducerFrequency" placeholder="请选择换能器频率" style="width: 100%;">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in  dictionary('HS_Device_TransducerFrequency')" :key="'GateBackEquipmentId' + jdx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供电方式" prop="PowerSupplyMode">
            <el-select v-model="deviceForm.PowerSupplyMode" placeholder="请选择供电方式" style="width: 100%;">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in  dictionary('HS_Device_PowerSupplyMode')" :key="'GateBackEquipmentId' + jdx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="水位传感器安装方式" prop="WaterLevelSensorInstalledMethod">
            <el-select v-model="deviceForm.WaterLevelSensorInstalledMethod" placeholder="请选择水位传感器安装方式" style="width: 100%;">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in dictionary('HS_Device_WaterLevelSensorInstalledMethod')" :key="'GateBackEquipmentId' + jdx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="水位传感器数量" prop="WaterLevelSensorCount">
            <!-- <el-input v-model="deviceForm.WaterLevelSensorCount"></el-input> -->
            <!-- <el-input-number v-model="deviceForm.WaterLevelSensorCount" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number> -->
            <el-select v-model="deviceForm.WaterLevelSensorCount" placeholder="水位传感器数量" style="width: 100%;">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in dictionary('HS_Device_WaterLevelSensorCount')" :key="'WaterLevelSensorCount' + jdx"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <!-- 开放式超声波明渠流量计 -->
        <template v-if="deviceForm.DeviceTypeId == 'HS_KFSCSBMQLLJ'">
          <el-form-item label="渠道顶宽(mm)" prop="ChannelTopWidth">
            <!-- <el-input v-model="deviceForm.ChannelTopWidth"></el-input> -->
            <el-input-number v-model="deviceForm.ChannelTopWidth" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="渠道底宽(mm)" prop="ChannelBottomWidth">
            <!-- <el-input v-model="deviceForm.ChannelBottomWidth"></el-input> -->
            <el-input-number v-model="deviceForm.ChannelBottomWidth" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="渠道深度(mm)" prop="ChannelDeep">
            <!-- <el-input v-model="deviceForm.ChannelDeep"></el-input> -->
            <el-input-number v-model="deviceForm.ChannelDeep" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="声道数" prop="ChannelCount">
            <!-- <el-input v-model="deviceForm.ChannelCount"></el-input> -->
            <el-input-number v-model="deviceForm.ChannelCount"   :precision="0" :step="1" :min="1" :max="16" style="width: 100%;"></el-input-number>
          </el-form-item>
          <!-- <el-form-item label="第一T型宽(mm)" prop="FirstTShapedWidth">
            <el-input v-model="deviceForm.FirstTShapedWidth"></el-input>
          </el-form-item>
          <el-form-item label="第二T型宽(mm)" prop="SecTShapedWidth">
            <el-input v-model="deviceForm.SecTShapedWidth"></el-input>
          </el-form-item>
          <el-form-item label="第一T型高(mm)" prop="FirstTShapedHeight">
            <el-input v-model="deviceForm.FirstTShapedHeight"></el-input>
          </el-form-item>
          <el-form-item label="第二T型高(mm)" prop="SecTShapedHeight">
            <el-input v-model="deviceForm.SecTShapedHeight"></el-input>
          </el-form-item>
        
          <el-form-item label="分布规则" prop="DistributionRules">
            <el-input v-model="deviceForm.DistributionRules"></el-input>
          </el-form-item>
          <el-form-item label="第1~N个声道的安装高度(mm)" prop="ChannelInstalledHeight">
            <el-input v-model="deviceForm.ChannelInstalledHeight"></el-input>
          </el-form-item>
          <el-form-item label="水位传感器安装高度(mm)" prop="WaterLevelSensorInstalledHeight">
            <el-input v-model="deviceForm.WaterLevelSensorInstalledHeight"></el-input>
          </el-form-item> -->
          <el-form-item label="供电方式" prop="PowerSupplyMode">
            <el-select v-model="deviceForm.PowerSupplyMode" placeholder="请选择供电方式" style="width: 100%;">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in  dictionary('HS_Device_PowerSupplyMode')" :key="'GateBackEquipmentId' + jdx"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <!-- 无线式超声波明渠流量计 -->
        <template v-if="deviceForm.DeviceTypeId == 'HS_WXSCSBMQLLJ'">
          <el-form-item label="渠道顶宽(mm)" prop="ChannelTopWidth">
            <!-- <el-input v-model="deviceForm.ChannelTopWidth"></el-input> -->
            <el-input-number v-model="deviceForm.ChannelTopWidth" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="渠道底宽(mm)" prop="ChannelBottomWidth">
            <!-- <el-input v-model="deviceForm.ChannelBottomWidth"></el-input> -->
            <el-input-number v-model="deviceForm.ChannelBottomWidth" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="渠道深度(mm)" prop="ChannelDeep">
            <!-- <el-input v-model="deviceForm.ChannelDeep"></el-input> -->
            <el-input-number v-model="deviceForm.ChannelDeep" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <!-- <el-form-item label="第一T型宽(mm)" prop="FirstTShapedWidth">
            <el-input v-model="deviceForm.FirstTShapedWidth"></el-input>
          </el-form-item>
          <el-form-item label="第二T型宽(mm)" prop="SecTShapedWidth">
            <el-input v-model="deviceForm.SecTShapedWidth"></el-input>
          </el-form-item>
          <el-form-item label="第一T型高(mm)" prop="FirstTShapedHeight">
            <el-input v-model="deviceForm.FirstTShapedHeight"></el-input>
          </el-form-item>
          <el-form-item label="第二T型高(mm)" prop="SecTShapedHeight">
            <el-input v-model="deviceForm.SecTShapedHeight"></el-input>
          </el-form-item> -->
          <el-form-item label="声道数" prop="ChannelCount">
            <!-- <el-input v-model="deviceForm.ChannelCount"></el-input> -->
            <el-input-number v-model="deviceForm.ChannelCount"  :precision="0" :step="1" :min="1" :max="8" style="width: 100%;"></el-input-number>
          </el-form-item>
          <!-- <el-form-item label="分布规则" prop="DistributionRules">
            <el-input v-model="deviceForm.DistributionRules"></el-input>
          </el-form-item>
          <el-form-item label="第1~N个声道的安装高度(mm)" prop="ChannelInstalledHeight">
            <el-input v-model="deviceForm.ChannelInstalledHeight"></el-input>
          </el-form-item>
          <el-form-item label="水位传感器安装高度(mm)" prop="WaterLevelSensorInstalledHeight">
            <el-input v-model="deviceForm.WaterLevelSensorInstalledHeight"></el-input>
          </el-form-item> -->
          <el-form-item label="供电方式" prop="PowerSupplyMode">
            <el-select v-model="deviceForm.PowerSupplyMode" placeholder="请选择供电方式" style="width: 100%;">
              <el-option :label="j.name" :value="j.dtCode" v-for="(j,jdx) in  dictionary('HS_Device_PowerSupplyMode')" :key="'GateBackEquipmentId' + jdx"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="淤积检测点数量" prop="SedimentationDetectionCount">
            <el-input v-model="deviceForm.SedimentationDetectionCount"></el-input>
          </el-form-item> -->
        </template>
        <!-- 巴歇尔槽明渠流量计 -->
        <template v-if="deviceForm.DeviceTypeId == 'HS_BXECMQLLJ'">
          <el-form-item label="槽号" prop="Slot">
            <!-- <el-input v-model="deviceForm.Slot"></el-input> -->
            <el-input-number v-model="deviceForm.Slot"   :precision="0" :step="1" :min="1" :max="30" style="width: 100%;"></el-input-number>
          </el-form-item>
          <el-form-item label="B值(mm)" prop="BValue">
            <!-- <el-input v-model="deviceForm.BValue"></el-input> -->
            <el-input-number v-model="deviceForm.BValue" :precision="2" :step="1" :min="0" style="width: 100%;"></el-input-number>
          </el-form-item>
          <!-- <el-form-item label="水位传感器安装高度(mm)" prop="WaterLevelSensorInstalledHeight">
            <el-input v-model="deviceForm.WaterLevelSensorInstalledHeight"></el-input>
          </el-form-item> -->
        </template>
        <!-- 水位流量计 -->
        <!-- <template v-if="deviceForm.DeviceTypeId == 'HS_SWLLJ'">
          <el-form-item label="水位传感器安装高度(mm)" prop="WaterLevelSensorInstalledHeight">
            <el-input v-model="deviceForm.WaterLevelSensorInstalledHeight"></el-input>
          </el-form-item>
        </template> -->
        <!-- 水位计 -->
        <!-- <template v-if="deviceForm.DeviceTypeId == 'HS_SWJ'">
          <el-form-item label="水位计安装高度(mm)" prop="WaterLevelGaugeInstalledHeight">
            <el-input v-model="deviceForm.WaterLevelGaugeInstalledHeight"></el-input>
          </el-form-item>
        </template> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" size="mini" @click="cancelDialog">取消</el-button>
        <el-button type="success" size="mini" @click="submitForm('deviceForm')">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="绑定萤石云" :visible.sync="bindCameraDialog" width="30%">
      <el-form :model="cameraForm" :rules="cameraFormRules" ref="cameraForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="AppKey" prop="accessKey">
          <el-input v-model="cameraForm.accessKey"></el-input>
        </el-form-item>
        <el-form-item label="Secret" prop="accessSecret">
          <el-input v-model="cameraForm.accessSecret"></el-input>
        </el-form-item>
        <el-form-item label="预览地址" prop="accessURL">
          <el-input v-model="cameraForm.accessURL"></el-input>
        </el-form-item>
        <!-- 新增小程序配置 -->
        <!-- <el-form-item label="AccessToken" prop="accessToken">
          <el-input v-model="cameraForm.accessToken"></el-input>
        </el-form-item> -->
        <el-form-item label="设备序列号" prop="deviceSerial">
          <el-input v-model="cameraForm.deviceSerial"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bindCameraDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitCameraForm('cameraForm')">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 分配用户 -->
    <template v-if="userDialog">
      <distributionUser :userDialog="userDialog" :list="usersList" @handleDistributionUser="handleDistributionUser" @cancelUserDialog="cancelUserDialog"></distributionUser>
    </template>

    <!-- 下发报警配置 -->
    <el-dialog title="下发报警配置" :visible.sync="alarmConfigDialog" width="30%">
      <el-form :model="alarmCinfigForm" :rules="alarmCinfigFormRules" ref="alarmCinfigForm" label-width="180px" class="demo-ruleForm">
        <el-form-item label="是否推送报警信息" prop="enableAlarm">
          <el-switch v-model="alarmCinfigForm.enableAlarm"></el-switch>
        </el-form-item>
        <el-form-item label="报警间隔（分钟）" prop="alarmSendInterval">
          <el-input-number v-model="alarmCinfigForm.alarmSendInterval" :min="30"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alarmConfigDialog = false">取 消</el-button>
        <el-button type="primary" @click="LssuedWarning('alarmCinfigForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import {listToTreeSelect} from '@/utils'//树结构转换
  import * as users from '@/api/users'//获取用户接口
  import { getToken } from '@/utils/auth'
  import TMap from '@/components/base/TMap'//通用地图组件
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
      generalForm,
      TMap
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      const DeviceNo = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请输入设备编号"));
        }
        if (value.length != 8) {
          callback(new Error("必须等于8位"));
        } else {
          callback();
        }
      };
      const VersionNo = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请输入软件版本号"));
        }
        if (value.length != 8) {
          callback(new Error("必须等于8位"));
        } else {
          callback();
        }
      };
      const InstalledLocation = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请输入安装地点"));
        }
        if (value.length >10 ) {
          callback(new Error("长度不大于10个字符"));
        } else {
          callback();
        }
      };
      const CropTypeName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请输入作物类型"));
        }
        if (value.length >10 ) {
          callback(new Error("长度不大于10个字符"));
        } else {
          callback();
        }
      };
      const CommonPwd = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请输入密码"));
        }
        if (value.length !=4 ) {
          callback(new Error("长度必须为四位数"));
        } else {
          callback();
        }
      };
      
      return {
        GateBackEquipmentData:[],
        orgsTreearr:[],
        orgsTrees:[],
        fileList:[],
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          HS_Area:"",
          TreeIds:[],
          key: ''
        },
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{ },//添加表单
        
        
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        mainMultipleSelection:[],//多选数据
        mainWaterMeter:[],//选中的总表
        
        userDialog:false,//用户弹窗
        usersList:[],//所有用户
        titleIndex:0,
        addTitle:['新增设备','编辑设备'],//添加弹窗标题
        deviceForm:{//表单
          // 公用基本信息
          DeviceNo:'',//设备编号
          VersionNo:'',//软件版本号
          InstalledLocation:'',//安装地点
          InstalledTime:"",
          AlarmSendInterval:"",
          Longitude:'',//经度坐标
          Latitude:'',//纬度坐标
          WaterConsumptionNorm:'',//用水定额(m³)
          IrrigatedAcreage:'',//灌溉亩数(亩)
          CropTypeName:'',//作物类型
          BelongAreaLinks:[],//分支机构
          InstalledImage:'',//设备安装图片
          DeviceRemark:'',//设备描述
          DeviceTypeId:'',//设备类型
          //设备分类信息 ,区分设备
          GateWidth: "", //闸板宽
          GateHeight: "", //闸板高
          GateMaximumStroke: "", //闸板最大行程
          GateFrontEquipmentId: "", //闸前设备
          GateBackEquipmentId: "", //闸后设备
          GateFrontEquipmentNo:"",
          GateBackEquipmentNo:"",
          PowerSupplyMode: "", //供电方式
          MotorVoltage: "", //电机电压
          MotorPower: "", //电机功率
          MeasuringBoxWidth: "", //测箱宽h
          MeasuringBoxHeight: "", //测箱高w
          TransducerCount: "", //换能器数量n
          TransducerLowestLevelDistance: "", //最下层换能器距离底板距
          TransducerTopmostLevelDistance: "", //最上层换能器距离顶板距
          TransducerPattern: "", //换能器分布规律
          TransducerFrequency: "", //换能器频率
          WaterLevelSensorInstalledMethod: "", //水位传感器安装方式
          WaterLevelSensorCount: "", //水位传感器数量
          ChannelTopWidth: "", //渠道顶宽
          ChannelBottomWidth: "", //渠道底宽
          ChannelDeep: "", //渠道深度
          FirstTShapedWidth: "", //第一T型宽
          SecTShapedWidth: "", //第二T型宽
          FirstTShapedHeight: "", //第一T型高
          SecTShapedHeight: "", //第二T型高
          ChannelCount: "", //声道数
          DistributionRules: "", //分布规则
          ChannelInstalledHeight: "", //第1~N个声道的安装高度
          WaterLevelSensorInstalledHeight: "", //水位传感器安装高度
          SedimentationDetectionCount: "", //淤积检测点数量
          Slot: "", //槽号
          BValue: "", //B值
          WaterLevelGaugeInstalledHeight: "", //水位计安装高度
        },
        deviceFormRules:{//校验
          DeviceNo:  [{ required: true, validator: DeviceNo, trigger: "blur" }],
          VersionNo: [{ required: true, validator: VersionNo, trigger: "blur" }],
          InstalledLocation: [{ required: true, validator: InstalledLocation, trigger: "blur" }], 
          InstalledTime: [{ required: true, message: '请选择安装时间', trigger: 'blur' },],
          Longitude: [{ required: true, message: '请输入经度坐标', trigger: 'blur' },],
          GateFrontEquipmentId: [{ required: true, message: '请选择闸前设备', trigger: 'blur' },],
          GateBackEquipmentId: [{ required: true, message: '请选择闸后设备', trigger: 'blur' },],
          Latitude: [{ required: true, message: '请输入纬度坐标', trigger: 'blur' },],
          WaterConsumptionNorm: [{ required: true, message: '请输入用水定额', trigger: 'blur' },],
          IrrigatedAcreage: [{ required: true, message: '请输入灌溉亩数', trigger: 'blur' },],
          CropTypeName:  [{ required: true, validator: CropTypeName, trigger: "blur" }], 
          CommonPwd:  [{ required: true, validator: CommonPwd, trigger: "blur" }], 
          BelongAreaLinks: [
            { required: true, message: '请选择分支机构', trigger: 'change' }
          ],
          DeviceTypeId: [
            { required: true, message: '请选择设备类型', trigger: 'change' }
          ],
        },
        // 上传图片相关
        dialogImageUrl: '',
        dialogImageVisible: false,
        disabledImage: false,
        tokenHeader: {'X-Token': getToken()},
        baseURL: process.env.VUE_APP_BASE_API, // api的base_url
        baseImgURL:process.env.VUE_APP_BASE_IMG_URL, // api的imgurl
        showMap:false,//显示地图
        bindCameraDialog:false,//萤石云绑定
        cameraForm:{
          accessKey:"",
          accessSecret:"",
          accessURL:"",
          deviceSerial:'',
        },
        //校验
        cameraFormRules:{
          accessKey: [
            { required: true, message: '请输入AppKey', trigger: 'blur' },
          ],
          accessSecret: [
            { required: true, message: '请输入Secret', trigger: 'blur' },
          ],
          accessURL: [
            { required: true, message: '请输入accessURL', trigger: 'blur' },
          ],
          // accessToken: [
          //   { required: true, message: '请输入accessToken', trigger: 'blur' },
          // ],
          deviceSerial: [
            { required: true, message: '请输入设备序列号', trigger: 'blur' },
          ],
        },
        alarmConfigDialog:false,//下发 报警配置
        alarmCinfigForm:{//报警配置表单
          enableAlarm:true,//是否推送报警信息
          alarmSendInterval:30,//报警间隔
        },
        alarmCinfigFormRules:{},//报警配置校验
      }
    },
    watch: {

    },
    computed: {
      orgsTree(){
        return this.$store.state.allInstitution
      },
      dictionary(dicId){
        return function (dicId){
           let disData = this.$store.state.allCategorysData.filter((item)=>{return item.id==dicId})
           if(disData&&disData.length>0){
            return disData[0].categoryList
           }else{
            return []
           }
        }
      },
    },
    filters: {
      
    },
    created() {
      this.getList()
      this.getUserData()//获取用户数据
      
    },
    mounted() {
      this.getLoadUsersOfTenant()
      
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      getLoadUsersOfTenant(){
        basicDataApi.GetLoadUsersOfTenant().then((res)=>{
         if(res.result.length>0){
          res.result.forEach((item)=>{
            item.value= item.id
            item.label =item.name
          })
          this.orgsTrees =listToTreeSelect(res.result)
        }
        }).catch(()=>{

        })
      },
      getGateFrontEquipment(e){
        this.GateBackEquipmentData =[]
        this.deviceForm.GateFrontEquipmentNo =this.dictionary('HS_Device_GateFrontEquipment').filter((item)=>{return item.dtCode == e})[0].name
        this.deviceForm.GateBackEquipmentNo =""
        this.deviceForm.GateBackEquipmentId =""
        let  data  = this.dictionary('HS_Device_GateBackEquipment')
        if(this.deviceForm.GateFrontEquipmentId=="HS_XSCSBMQLLJ"){
          this.GateBackEquipmentData=data.filter((item)=>{return item.dtCode == "HS_SWJ"})
        }else if(this.deviceForm.GateFrontEquipmentId=="HS_KFSCSBMQLLJ"){
          this.GateBackEquipmentData=data.filter((item)=>{return item.dtCode == "HS_SWJ"})
        }else{ //HS_SWJ
          this.GateBackEquipmentData=data.filter((item)=>{return item.dtCode == "HS_BXECMQLLJ"||item.dtCode == "HS_KFSCSBMQLLJ"||item.dtCode == "HS_SWLLJ"||item.dtCode == "HS_WXSCSBMQLLJ"||item.dtCode == "HS_XSCSBMQLLJ"})
        }
        // this.$forceUpdate()
        // console.log(this.dictionary('HS_Device_GateBackEquipment'),789987)
      },
      getGateBackEquipment(e){
        this.deviceForm.GateBackEquipmentNo =this.dictionary('HS_Device_GateBackEquipment').filter((item)=>{return item.dtCode == e})[0].name
      },
      handleInput() {
        // 使用正则表达式过滤非数字字符
        this.deviceForm.DeviceNo = this.deviceForm.DeviceNo.replace(/\D/g, '').substring(0, 8);
      },
      handleInputPwd() {
        // 使用正则表达式过滤非数字字符
        this.deviceForm.CommonPwd = this.deviceForm.CommonPwd.replace(/\D/g, '').substring(0, 4);
      },
      handleInputver() {
        // 使用正则表达式过滤非数字字符
        this.deviceForm.VersionNo = this.deviceForm.VersionNo.replace(/\D/g, '').substring(0, 8);
      },
      // 关闭弹窗
      clearData(){
        this.$refs.deviceForm.resetFields();
        this.$refs.uploadPic.clearFiles()
      },
      // 文件超出
      handleExceed(){
        this.$message.error("只允许上传一个文件")
        return
      },
      // 获取机构id以及名称
      getBelongAreaLinks(e){
        if(e){
          this.deviceForm.BelongAreaId =e[e.length-1]
          this.orgsTreearr.forEach((item)=>{
            if(item.id == e[e.length-1]){
              this.deviceForm.BelongAreaName = item.name
            }
          })
         
        }
        console.log(this.deviceForm,741147)
        // console.log(e)
      },
      // 获取设备名称
      getDeviceTypeName(e){
        let data =this.$store.state.deviceType.filter((item)=>{ return item.dtCode == e})
        this.deviceForm.DeviceTypeName =data[0].name
      },
      // 获取用户数据
      getUserData(){
        var data={
          page: 1,
          limit: 50
        }
        users.getList(data).then(response => {
          if(response.code == 200){
            this.usersList = response.data
          }
        })
      },
      // 节点点击
      handleNodeClick(ids,data) {
        // console.log(data)
        this.mainWaterMeter = data//选中总表赋值
        this.listQuery.TreeIds = ids
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.HS_Area=data.id
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
          if(this.mainMultipleSelection.length >0 ){
              this.handleDelete(this.mainMultipleSelection)
            }else{
              this.$message({
                message: `请选择至少一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'btnAdd'://添加
          this.getLoadUsersOfTenant()
            this.FormDialog = true
            this.titleIndex = 0
            this.deviceForm={//表单
              // 公用基本信息
              DeviceNo:'',//设备编号
              VersionNo:'',//软件版本号
              CommonPwd:"",
              InstalledLocation:'',//安装地点
              Longitude:'',//经度坐标
              AlarmSendInterval:"",
              Latitude:'',//纬度坐标
              WaterConsumptionNorm:'',//用水定额(m³)
              IrrigatedAcreage:'',//灌溉亩数(亩)
              CropTypeName:'',//作物类型
              BranchName:'',//分支机构
              // FirstLevelBranchName:'',//一级分支机构
              // SecLevelBranchName:'',//二级分支机构
              // ThirdLevelBranchName:'',//三级分支机构
              InstalledImage:'',//设备安装图片
              DeviceRemark:'',//设备描述
              DeviceTypeId:'',//设备类型
              DeviceTypeName:"",//设备名称
              //设备分类信息 ,区分设备
              GateWidth: "", //闸板宽
              GateHeight: "", //闸板高
              GateMaximumStroke: "", //闸板最大行程
              GateFrontEquipmentId: "", //闸前设备
              GateBackEquipmentId: "", //闸后设备
              GateFrontEquipmentNo:"",
              GateBackEquipmentNo:"",
              PowerSupplyMode: "", //供电方式
              MotorVoltage: "", //电机电压
              MotorPower: "", //电机功率
              MeasuringBoxWidth: "", //测箱宽h
              MeasuringBoxHeight: "", //测箱高w
              TransducerCount: "", //换能器数量n
              TransducerLowestLevelDistance: "", //最下层换能器距离底板距
              TransducerTopmostLevelDistance: "", //最上层换能器距离顶板距
              TransducerPattern: "", //换能器分布规律
              TransducerFrequency: "", //换能器频率
              WaterLevelSensorInstalledMethod: "", //水位传感器安装方式
              WaterLevelSensorCount: "", //水位传感器数量
              ChannelTopWidth: "", //渠道顶宽
              ChannelBottomWidth: "", //渠道底宽
              ChannelDeep: "", //渠道深度
              FirstTShapedWidth: "", //第一T型宽
              SecTShapedWidth: "", //第二T型宽
              FirstTShapedHeight: "", //第一T型高
              SecTShapedHeight: "", //第二T型高
              ChannelCount: "", //声道数
              DistributionRules: "", //分布规则
              ChannelInstalledHeight: "", //第1~N个声道的安装高度
              WaterLevelSensorInstalledHeight: "", //水位传感器安装高度
              SedimentationDetectionCount: "", //淤积检测点数量
              Slot: "", //槽号
              BValue: "", //B值
              WaterLevelGaugeInstalledHeight: "", //水位计安装高度
            }
            break
          case 'btnEdit'://编辑
          this.getLoadUsersOfTenant()
            if(this.mainMultipleSelection.length == 1){
              const newObj = Object.entries(JSON.parse(JSON.stringify(this.mainMultipleSelection[0]))).reduce((acc, [key, value]) => {
                acc[key.charAt(0).toUpperCase() + key.slice(1)] = value;
                return acc;
              }, {});
              // console.log(newObj.BelongAreaLinks,78999)
              this.deviceForm = newObj
              this.deviceForm.BelongAreaLinks= JSON.parse(this.deviceForm.BelongAreaLinks)
              if(this.deviceForm.InstalledImage&&this.deviceForm.InstalledImage!=''){
                this.fileList=[{url:this.baseImgURL+'/File/'+this.deviceForm.InstalledImage,name:new Date().getTime()}]
              }else{
                this.fileList=[]
              }
              let  data  = this.dictionary('HS_Device_GateBackEquipment')
              if(this.deviceForm.GateFrontEquipmentId=="HS_XSCSBMQLLJ"){
                  this.GateBackEquipmentData=data.filter((item)=>{return item.dtCode == "HS_SWJ"})
                }else if(this.deviceForm.GateFrontEquipmentId=="HS_KFSCSBMQLLJ"){
                  this.GateBackEquipmentData=data.filter((item)=>{return item.dtCode == "HS_SWJ"})
                }else{ //HS_SWJ
                  this.GateBackEquipmentData=data.filter((item)=>{return item.dtCode == "HS_BXECMQLLJ"||item.dtCode == "HS_KFSCSBMQLLJ"||item.dtCode == "HS_SWLLJ"||item.dtCode == "HS_WXSCSBMQLLJ"||item.dtCode == "HS_XSCSBMQLLJ"})
                }
              this.FormDialog = true
              this.titleIndex = 1
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'distributionUser'://分配用户
            // 测试
            if(this.mainMultipleSelection.length<=0){
              this.$message.error("请选择设备")
              return
            }
            this.userDialog = true
            break
          case 'bindCamera'://绑定萤石云
          if(this.mainMultipleSelection.length ==1 ){
            this.cameraForm.id= this.mainMultipleSelection[0].id
            this.cameraForm.accessKey= this.mainMultipleSelection[0].accessKey
            this.cameraForm.accessSecret= this.mainMultipleSelection[0].accessSecret
            this.cameraForm.accessURL= this.mainMultipleSelection[0].accessURL
            this.cameraForm.deviceSerial= this.mainMultipleSelection[0].deviceSerial
            this.bindCameraDialog = true
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
           
            break
          case 'btnLssued'://下发报警配置
            // 测试
            if(this.mainMultipleSelection.length!=1){
              this.$message.error("请选择一个设备进行下发!")
              return
            }
            this.alarmCinfigForm.enableAlarm = this.mainMultipleSelection[0].enableAlarm//是否推送报警信息
            this.alarmCinfigForm.alarmSendInterval = this.mainMultipleSelection[0].alarmSendInterval//报警间隔
            this.alarmConfigDialog = true//下发报警配置
            break
          default:
            break
        }
      },
      // 下发报警配置
      LssuedWarning(){
        var data = {
          deviceNo:this.mainMultipleSelection[0].deviceNo,
          enableAlarm:this.alarmCinfigForm.enableAlarm,
          alarmSendInterval:this.alarmCinfigForm.alarmSendInterval,
        }
        basicDataApi.jczlSbglLssued(data).then(response => {
          if(response.code == 200){
            if(response.result.executeState!=1){
              this.$notify({
                message: response.result.remark,
                type: 'warning',
                duration: 2000
              });
            }else{
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.alarmConfigDialog = false
              this.getList()
            }
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.$notify({
            message: response.message,
            type: 'warning',
            duration: 2000
          });
        })
      },
      // 获取数据
      getList() {
        var searchQuery = Object.assign({},this.listQuery)
        this.loading = true
        basicDataApi.jczlSbglSearch(searchQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              if(i.key == 'surfaceNum'){
                i.columnWidth = 160
              }
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.listQuery.HS_Area =''
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.$confirm(`确定要删除选中记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          let data  =[]
          row.forEach((item)=>{
            data.push(item.id)
          })
          basicDataApi.jczlSbglDel(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // add弹窗取消
      cancelDialog(val){
        // this.FormDialog = val
        this.FormDialog=false
      },
      // add弹窗提交
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let subData = JSON.parse(JSON.stringify(this.deviceForm))
            subData.BelongAreaLinks = JSON.stringify(subData.BelongAreaLinks)
            if(this.titleIndex == 0){//新增
              basicDataApi.jczlSbglAdd(subData).then(response => {
              if(response.code == 200){
                this.$notify({
                  message: response.message,
                  type: 'success',
                  duration: 2000
                });
                
                this.FormDialog = false
                this.getList()
              }else{
                this.$notify({
                  message: "添加失败",
                  type: 'error',
                  duration: 2000
                });
              }
            }).catch(()=>{
            })
            }else{ //编辑
              let subData = JSON.parse(JSON.stringify(this.deviceForm))
              subData.BelongAreaLinks = JSON.stringify(subData.BelongAreaLinks)
              basicDataApi.jczlSbglEdit(subData).then(response => {
                if(response.code == 200){
                  this.$notify({
                    message: response.message,
                    type: 'success',
                    duration: 2000
                  });
                  
                  this.FormDialog = false
                  this.getList()
                }else{
                  this.$notify({
                    message: response.message,
                    type: 'warning',
                    duration: 2000
                  });
                }
              }).catch(()=>{
              })

            }
            // basicDataApi.jzqglAdd(this.deviceForm).then(response => {
            //   if(response.code == 200){
            //     this.$notify({
            //       message: response.message,
            //       type: 'success',
            //       duration: 2000
            //     });
            //     this.FormDialog = false
            //     this.getList()
            //     this.$refs[formName].resetFields();
            //   }else{
            //     this.$notify({
            //       message: response.message,
            //       type: 'warning',
            //       duration: 2000
            //     });
            //   }
            // }).catch(()=>{
            //   this.FormDialog = false
            // })
          } else {
            
            return false;
          }
        });
      },
      
      //表格多选
      handleSelectionChange(val){
        this.mainMultipleSelection = val
      },
      
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },
      // 分配用户保存
      handleDistributionUser(val) {
        // 选择右侧数据逻辑
        // var areaIds = []
        // var ids = []
        // this.mainMultipleSelection.forEach(i=>{
        //   areaIds.push(i.id)
        // })
        // val.forEach(i=>{
        //   ids.push(i.id)
        // })
        // var data = {
        //   areaIds:areaIds,
        //   ids:ids
        // }
        if(val.length<=0){
          this.$message.error("请选择用户")
          return
        }
        var UserList = []
        var IdList=[]
        val.forEach(i=>{
          let ss={}
          ss.personInChargeId =i.id
          ss.personInChargeName=  i.name
          UserList.push(ss)
        })
        this.mainMultipleSelection.forEach(i=>{
          IdList.push(i.id)
        })
        var data = {
          IdList:IdList,
          UserList:UserList
        }
        basicDataApi.sbglSetChargePerson(data).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
            this.userDialog = false
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          
        })
      },
      //分配用户取消
      cancelUserDialog(val){
        this.userDialog = val
      },
      // 上传图片删除
      handleRemove(file) {
        this.$refs.uploadPic.clearFiles()
        // console.log(file,99999)
        // console.log(file);
      },
      // 上传图片查看
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogImageVisible = true;
      },
      //上传成功
      handleUploadSuccess(res, file) {
        if(res.code==500){
          this.$refs.uploadPic.clearFiles()
          this.$message.error("上传失败")
          return
        }
        this.deviceForm.InstalledImage =res.result[0].filePath
        //  console.log(res,file,789987)
      },

      //地图选点
      confirmLocation(e) {
        this.deviceForm.Longitude = e.lng
        this.deviceForm.Latitude = e.lat
        this.deviceForm.InstalledLocation = e.addr.substring(0,10)
        this.showMap = false
      },
      //绑定萤石云提交
      submitCameraForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            basicDataApi.jczlSbglSetYSY(this.cameraForm).then(response => {
              if(response.code == 200&&response.result.executeState==1){
                this.$notify({
                  message: response.message,
                  type: 'success',
                  duration: 2000
                });
                this.$refs[formName].resetFields();
                this.bindCameraDialog = false 
                this.getList()
              }else{
                this.$message.error("设置指令下发失败") 
              }
            }).catch(()=>{
              this.$message.error("设置指令下发失败")
            })
           
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    },
  }

</script>

<style lang="scss" scoped>
  .special{
    ::v-deep .el-dialog__body{
      padding: 0  !important;
    }
  }
  ::v-deep .el-cascader-panel {
    max-height: 600px;
  }
</style>
