var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: { display: "inline-block" },
              attrs: {
                searchList: _vm.$jczlSbglSearch,
                listQuery: _vm.listQuery,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "app-container flex-item" }, [
        _c("div", { ref: "dragBox", staticClass: "dragBox" }, [
          _c(
            "div",
            { staticClass: "dragBox_left" },
            [
              _c(
                "el-card",
                {
                  staticClass: "body-small",
                  staticStyle: { height: "100%", overflow: "auto" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("basic-tree", {
                    attrs: {
                      receiveTreedata: _vm.orgsTree,
                      isShowCheck: false,
                      isSelectAll: true,
                      hasIcon: true,
                      incomeTreeData: true,
                    },
                    on: {
                      handleNodeClick: _vm.handleNodeClick,
                      getCheckedKeys: _vm.getCheckedKeys,
                      nodeClickOne: _vm.nodeClickOne,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dragBox_resize", attrs: { title: "收缩侧边栏" } },
            [_vm._v(" ⋮ ")]
          ),
          _c("div", { staticClass: "dragBox_mid" }, [
            _c(
              "div",
              {
                staticClass: "bg-white",
                staticStyle: { height: "100%" },
                attrs: { id: "table_box_height" },
              },
              [
                _c("basic-table", {
                  attrs: {
                    tableHeight: _vm.tableHeight,
                    tableHeaderList: _vm.firstHeaderList,
                    tableData: _vm.tableData,
                    listQuery: _vm.listQuery,
                    tableTotal: _vm.tableTotal,
                    hasSelection: true,
                  },
                  on: {
                    pagination: _vm.handleCurrentChange,
                    tableRowClick: _vm.tableRowClick,
                    handleSelectionChange: _vm.handleSelectionChange,
                    tableColumnClick: _vm.tableColumnClick,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "special",
          attrs: {
            title: _vm.addTitle[_vm.titleIndex],
            visible: _vm.FormDialog,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.FormDialog = $event
            },
            close: _vm.clearData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "deviceForm",
              staticClass: "demo-deviceForm",
              staticStyle: {
                height: "500px",
                "overflow-y": "auto",
                "padding-right": "30px",
              },
              attrs: {
                model: _vm.deviceForm,
                rules: _vm.deviceFormRules,
                "label-width": "240px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "设备编号", prop: "DeviceNo" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入8位设备编号",
                      maxlength: 8,
                    },
                    on: { input: _vm.handleInput },
                    model: {
                      value: _vm.deviceForm.DeviceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "DeviceNo", $$v)
                      },
                      expression: "deviceForm.DeviceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通用密码", prop: "CommonPwd" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入4位通用密码",
                      maxlength: 4,
                    },
                    on: { input: _vm.handleInputPwd },
                    model: {
                      value: _vm.deviceForm.CommonPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "CommonPwd", $$v)
                      },
                      expression: "deviceForm.CommonPwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "报警发送间隔", prop: "AlarmSendInterval" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { min: 0, max: 9999999 },
                    model: {
                      value: _vm.deviceForm.AlarmSendInterval,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "AlarmSendInterval", $$v)
                      },
                      expression: "deviceForm.AlarmSendInterval",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "软件版本号", prop: "VersionNo" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入8位软件版本号",
                      maxlength: 8,
                    },
                    on: { input: _vm.handleInputver },
                    model: {
                      value: _vm.deviceForm.VersionNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "VersionNo", $$v)
                      },
                      expression: "deviceForm.VersionNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "安装地点", prop: "InstalledLocation" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入安装地点" },
                    model: {
                      value: _vm.deviceForm.InstalledLocation,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "InstalledLocation", $$v)
                      },
                      expression: "deviceForm.InstalledLocation",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经度坐标", prop: "Longitude" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { min: -180, max: 180 },
                    model: {
                      value: _vm.deviceForm.Longitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "Longitude", $$v)
                      },
                      expression: "deviceForm.Longitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "纬度坐标", prop: "Latitude" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { min: -90, max: 90 },
                    model: {
                      value: _vm.deviceForm.Latitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "Latitude", $$v)
                      },
                      expression: "deviceForm.Latitude",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "安装时间", prop: "InstalledTime" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.deviceForm.InstalledTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "InstalledTime", $$v)
                      },
                      expression: "deviceForm.InstalledTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用水定额(m³)",
                    prop: "WaterConsumptionNorm",
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { precision: 2, step: 1, min: 0 },
                    model: {
                      value: _vm.deviceForm.WaterConsumptionNorm,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "WaterConsumptionNorm", $$v)
                      },
                      expression: "deviceForm.WaterConsumptionNorm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "灌溉亩数(亩)", prop: "IrrigatedAcreage" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { precision: 2, step: 1, min: 0 },
                    model: {
                      value: _vm.deviceForm.IrrigatedAcreage,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "IrrigatedAcreage", $$v)
                      },
                      expression: "deviceForm.IrrigatedAcreage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "作物类型", prop: "CropTypeName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入作物类型" },
                    model: {
                      value: _vm.deviceForm.CropTypeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "CropTypeName", $$v)
                      },
                      expression: "deviceForm.CropTypeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分支机构", prop: "BelongAreaLinks" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      placeholder: "请选择分支机构(可搜索)",
                      options: _vm.orgsTrees,
                      props: { checkStrictly: true },
                      filterable: "",
                    },
                    on: { change: _vm.getBelongAreaLinks },
                    model: {
                      value: _vm.deviceForm.BelongAreaLinks,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "BelongAreaLinks", $$v)
                      },
                      expression: "deviceForm.BelongAreaLinks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备安装图片", prop: "InstalledImage" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadPic",
                      attrs: {
                        headers: _vm.tokenHeader,
                        action: _vm.baseURL + "/Files/Upload",
                        "file-list": _vm.fileList,
                        "list-type": "picture-card",
                        name: "files",
                        "on-success": _vm.handleUploadSuccess,
                        "on-exceed": _vm.handleExceed,
                        limit: 1,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "file",
                          fn: function ({ file }) {
                            return _c("div", {}, [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: { src: file.url, alt: "" },
                              }),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-preview",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePictureCardPreview(
                                            file
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-zoom-in",
                                      }),
                                    ]
                                  ),
                                  !_vm.disabledImage
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(file)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          },
                        },
                      ]),
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        visible: _vm.dialogImageVisible,
                        "append-to-body": "",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogImageVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备描述", prop: "DeviceRemark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", maxlength: 100 },
                    model: {
                      value: _vm.deviceForm.DeviceRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.deviceForm, "DeviceRemark", $$v)
                      },
                      expression: "deviceForm.DeviceRemark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备类型", prop: "DeviceTypeId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择设备类型" },
                      on: { change: _vm.getDeviceTypeName },
                      model: {
                        value: _vm.deviceForm.DeviceTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.deviceForm, "DeviceTypeId", $$v)
                        },
                        expression: "deviceForm.DeviceTypeId",
                      },
                    },
                    _vm._l(_vm.$store.state.deviceType, function (j, jdx) {
                      return _c("el-option", {
                        key: "deviceType" + jdx,
                        attrs: { label: j.name, value: j.dtCode },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.deviceForm.DeviceTypeId == "HS_CKYTHZM"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "闸板宽(mm)", prop: "GateWidth" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.GateWidth,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "GateWidth", $$v)
                            },
                            expression: "deviceForm.GateWidth",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "闸板高(mm)", prop: "GateHeight" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.GateHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "GateHeight", $$v)
                            },
                            expression: "deviceForm.GateHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "闸板最大行程(mm)",
                          prop: "GateMaximumStroke",
                        },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.GateMaximumStroke,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "GateMaximumStroke", $$v)
                            },
                            expression: "deviceForm.GateMaximumStroke",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "闸前设备",
                          prop: "GateFrontEquipmentId",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择闸前设备" },
                            on: { change: _vm.getGateFrontEquipment },
                            model: {
                              value: _vm.deviceForm.GateFrontEquipmentId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.deviceForm,
                                  "GateFrontEquipmentId",
                                  $$v
                                )
                              },
                              expression: "deviceForm.GateFrontEquipmentId",
                            },
                          },
                          _vm._l(
                            _vm.dictionary("HS_Device_GateFrontEquipment"),
                            function (j, jdx) {
                              return _c("el-option", {
                                key: "GateFrontEquipmentId" + jdx,
                                attrs: { label: j.name, value: j.dtCode },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "闸后设备",
                          prop: "GateBackEquipmentId",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择闸后设备" },
                            on: { change: _vm.getGateBackEquipment },
                            model: {
                              value: _vm.deviceForm.GateBackEquipmentId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.deviceForm,
                                  "GateBackEquipmentId",
                                  $$v
                                )
                              },
                              expression: "deviceForm.GateBackEquipmentId",
                            },
                          },
                          _vm._l(_vm.GateBackEquipmentData, function (j, jdx) {
                            return _c("el-option", {
                              key: "GateBackEquipmentId" + jdx,
                              attrs: { label: j.name, value: j.dtCode },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供电方式", prop: "PowerSupplyMode" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择供电方式" },
                            model: {
                              value: _vm.deviceForm.PowerSupplyMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.deviceForm, "PowerSupplyMode", $$v)
                              },
                              expression: "deviceForm.PowerSupplyMode",
                            },
                          },
                          _vm._l(
                            _vm.dictionary("HS_Device_PowerSupplyMode"),
                            function (j, jdx) {
                              return _c("el-option", {
                                key: "PowerSupplyMode" + jdx,
                                attrs: { label: j.name, value: j.dtCode },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "电机电压(V)", prop: "MotorVoltage" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.MotorVoltage,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "MotorVoltage", $$v)
                            },
                            expression: "deviceForm.MotorVoltage",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "电机功率(W)", prop: "MotorPower" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.MotorPower,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "MotorPower", $$v)
                            },
                            expression: "deviceForm.MotorPower",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.deviceForm.DeviceTypeId == "HS_XSCSBMQLLJ"
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "测箱宽h(mm)",
                          prop: "MeasuringBoxWidth",
                        },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.MeasuringBoxWidth,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "MeasuringBoxWidth", $$v)
                            },
                            expression: "deviceForm.MeasuringBoxWidth",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "测箱高w(mm)",
                          prop: "MeasuringBoxHeight",
                        },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.MeasuringBoxHeight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.deviceForm,
                                "MeasuringBoxHeight",
                                $$v
                              )
                            },
                            expression: "deviceForm.MeasuringBoxHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "换能器数量(n)",
                          prop: "TransducerCount",
                        },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 0, step: 1, min: 4, max: 32 },
                          model: {
                            value: _vm.deviceForm.TransducerCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "TransducerCount", $$v)
                            },
                            expression: "deviceForm.TransducerCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "换能器频率",
                          prop: "TransducerFrequency",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择换能器频率" },
                            model: {
                              value: _vm.deviceForm.TransducerFrequency,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.deviceForm,
                                  "TransducerFrequency",
                                  $$v
                                )
                              },
                              expression: "deviceForm.TransducerFrequency",
                            },
                          },
                          _vm._l(
                            _vm.dictionary("HS_Device_TransducerFrequency"),
                            function (j, jdx) {
                              return _c("el-option", {
                                key: "GateBackEquipmentId" + jdx,
                                attrs: { label: j.name, value: j.dtCode },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供电方式", prop: "PowerSupplyMode" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择供电方式" },
                            model: {
                              value: _vm.deviceForm.PowerSupplyMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.deviceForm, "PowerSupplyMode", $$v)
                              },
                              expression: "deviceForm.PowerSupplyMode",
                            },
                          },
                          _vm._l(
                            _vm.dictionary("HS_Device_PowerSupplyMode"),
                            function (j, jdx) {
                              return _c("el-option", {
                                key: "GateBackEquipmentId" + jdx,
                                attrs: { label: j.name, value: j.dtCode },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "水位传感器安装方式",
                          prop: "WaterLevelSensorInstalledMethod",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择水位传感器安装方式" },
                            model: {
                              value:
                                _vm.deviceForm.WaterLevelSensorInstalledMethod,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.deviceForm,
                                  "WaterLevelSensorInstalledMethod",
                                  $$v
                                )
                              },
                              expression:
                                "deviceForm.WaterLevelSensorInstalledMethod",
                            },
                          },
                          _vm._l(
                            _vm.dictionary(
                              "HS_Device_WaterLevelSensorInstalledMethod"
                            ),
                            function (j, jdx) {
                              return _c("el-option", {
                                key: "GateBackEquipmentId" + jdx,
                                attrs: { label: j.name, value: j.dtCode },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "水位传感器数量",
                          prop: "WaterLevelSensorCount",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "水位传感器数量" },
                            model: {
                              value: _vm.deviceForm.WaterLevelSensorCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.deviceForm,
                                  "WaterLevelSensorCount",
                                  $$v
                                )
                              },
                              expression: "deviceForm.WaterLevelSensorCount",
                            },
                          },
                          _vm._l(
                            _vm.dictionary("HS_Device_WaterLevelSensorCount"),
                            function (j, jdx) {
                              return _c("el-option", {
                                key: "WaterLevelSensorCount" + jdx,
                                attrs: { label: j.name, value: j.dtCode },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.deviceForm.DeviceTypeId == "HS_KFSCSBMQLLJ"
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "渠道顶宽(mm)",
                          prop: "ChannelTopWidth",
                        },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.ChannelTopWidth,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "ChannelTopWidth", $$v)
                            },
                            expression: "deviceForm.ChannelTopWidth",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "渠道底宽(mm)",
                          prop: "ChannelBottomWidth",
                        },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.ChannelBottomWidth,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.deviceForm,
                                "ChannelBottomWidth",
                                $$v
                              )
                            },
                            expression: "deviceForm.ChannelBottomWidth",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "渠道深度(mm)", prop: "ChannelDeep" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.ChannelDeep,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "ChannelDeep", $$v)
                            },
                            expression: "deviceForm.ChannelDeep",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "声道数", prop: "ChannelCount" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 0, step: 1, min: 1, max: 16 },
                          model: {
                            value: _vm.deviceForm.ChannelCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "ChannelCount", $$v)
                            },
                            expression: "deviceForm.ChannelCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供电方式", prop: "PowerSupplyMode" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择供电方式" },
                            model: {
                              value: _vm.deviceForm.PowerSupplyMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.deviceForm, "PowerSupplyMode", $$v)
                              },
                              expression: "deviceForm.PowerSupplyMode",
                            },
                          },
                          _vm._l(
                            _vm.dictionary("HS_Device_PowerSupplyMode"),
                            function (j, jdx) {
                              return _c("el-option", {
                                key: "GateBackEquipmentId" + jdx,
                                attrs: { label: j.name, value: j.dtCode },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.deviceForm.DeviceTypeId == "HS_WXSCSBMQLLJ"
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "渠道顶宽(mm)",
                          prop: "ChannelTopWidth",
                        },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.ChannelTopWidth,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "ChannelTopWidth", $$v)
                            },
                            expression: "deviceForm.ChannelTopWidth",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "渠道底宽(mm)",
                          prop: "ChannelBottomWidth",
                        },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.ChannelBottomWidth,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.deviceForm,
                                "ChannelBottomWidth",
                                $$v
                              )
                            },
                            expression: "deviceForm.ChannelBottomWidth",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "渠道深度(mm)", prop: "ChannelDeep" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.ChannelDeep,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "ChannelDeep", $$v)
                            },
                            expression: "deviceForm.ChannelDeep",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "声道数", prop: "ChannelCount" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 0, step: 1, min: 1, max: 8 },
                          model: {
                            value: _vm.deviceForm.ChannelCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "ChannelCount", $$v)
                            },
                            expression: "deviceForm.ChannelCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供电方式", prop: "PowerSupplyMode" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择供电方式" },
                            model: {
                              value: _vm.deviceForm.PowerSupplyMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.deviceForm, "PowerSupplyMode", $$v)
                              },
                              expression: "deviceForm.PowerSupplyMode",
                            },
                          },
                          _vm._l(
                            _vm.dictionary("HS_Device_PowerSupplyMode"),
                            function (j, jdx) {
                              return _c("el-option", {
                                key: "GateBackEquipmentId" + jdx,
                                attrs: { label: j.name, value: j.dtCode },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.deviceForm.DeviceTypeId == "HS_BXECMQLLJ"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "槽号", prop: "Slot" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 0, step: 1, min: 1, max: 30 },
                          model: {
                            value: _vm.deviceForm.Slot,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "Slot", $$v)
                            },
                            expression: "deviceForm.Slot",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "B值(mm)", prop: "BValue" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { precision: 2, step: 1, min: 0 },
                          model: {
                            value: _vm.deviceForm.BValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.deviceForm, "BValue", $$v)
                            },
                            expression: "deviceForm.BValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", size: "mini" },
                  on: { click: _vm.cancelDialog },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("deviceForm")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "绑定萤石云",
            visible: _vm.bindCameraDialog,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bindCameraDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "cameraForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.cameraForm,
                rules: _vm.cameraFormRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "AppKey", prop: "accessKey" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.cameraForm.accessKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.cameraForm, "accessKey", $$v)
                      },
                      expression: "cameraForm.accessKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Secret", prop: "accessSecret" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.cameraForm.accessSecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.cameraForm, "accessSecret", $$v)
                      },
                      expression: "cameraForm.accessSecret",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预览地址", prop: "accessURL" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.cameraForm.accessURL,
                      callback: function ($$v) {
                        _vm.$set(_vm.cameraForm, "accessURL", $$v)
                      },
                      expression: "cameraForm.accessURL",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备序列号", prop: "deviceSerial" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.cameraForm.deviceSerial,
                      callback: function ($$v) {
                        _vm.$set(_vm.cameraForm, "deviceSerial", $$v)
                      },
                      expression: "cameraForm.deviceSerial",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.bindCameraDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitCameraForm("cameraForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.userDialog
        ? [
            _c("distributionUser", {
              attrs: { userDialog: _vm.userDialog, list: _vm.usersList },
              on: {
                handleDistributionUser: _vm.handleDistributionUser,
                cancelUserDialog: _vm.cancelUserDialog,
              },
            }),
          ]
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "下发报警配置",
            visible: _vm.alarmConfigDialog,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.alarmConfigDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "alarmCinfigForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.alarmCinfigForm,
                rules: _vm.alarmCinfigFormRules,
                "label-width": "180px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否推送报警信息", prop: "enableAlarm" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.alarmCinfigForm.enableAlarm,
                      callback: function ($$v) {
                        _vm.$set(_vm.alarmCinfigForm, "enableAlarm", $$v)
                      },
                      expression: "alarmCinfigForm.enableAlarm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "报警间隔（分钟）",
                    prop: "alarmSendInterval",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { min: 30 },
                    model: {
                      value: _vm.alarmCinfigForm.alarmSendInterval,
                      callback: function ($$v) {
                        _vm.$set(_vm.alarmCinfigForm, "alarmSendInterval", $$v)
                      },
                      expression: "alarmCinfigForm.alarmSendInterval",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.alarmConfigDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.LssuedWarning("alarmCinfigForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }